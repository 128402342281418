import ReactWeather, { useOpenWeather } from "react-open-weather";
import Card from "../Card/Card";
import "./Weather.scss";
const Weather = () => {
  const { data, isLoading, errorMessage } = useOpenWeather({
    key: "7630ff384884a07759835cc3813c38bb",
    lat: "49.70681493577283",
    lon: "19.004492446108888",
    lang: "pl",
    unit: "metric", // values are (metric, standard, imperial)
  });
  return (
    <Card>
      <div className="weather">
        <ReactWeather
          isLoading={isLoading}
          errorMessage={errorMessage}
          data={data}
          lang="pl"
          locationLabel="Szczyrk"
          unitsLabels={{ temperature: "C", windSpeed: "Km/h" }}
          showForecast
        />
      </div>
    </Card>
  );
};

export default Weather;
