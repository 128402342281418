
import { CircularProgress } from "@mui/material";
import React, {useState} from "react";

import Alert from "../Alert/Alert";
import Card from "../Card/Card";
import "./Status.scss";
const Status = ({data, isFetching, isLoading}) => {
  
  const [isPerfect, setPerfect] = useState(false);
  const feedData = {
    temp: Number(data?.feeds[0].field1),
    humidity: Number(data?.feeds[0].field2),
  };
  const inside = (point, vs) => {
    var x = point[0],
      y = point[1];

    var inside = false;
    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      var xi = vs[i][0],
        yi = vs[i][1];
      var xj = vs[j][0],
        yj = vs[j][1];

      var intersect =
        (yi > y) !== (yj > y) && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }

    return inside;
  };
  const ComfortAlert = () => {
    if (inside([feedData?.temp, feedData?.humidity], sweetSpot)) {
      setPerfect(true);
      return <span style={{ color: "#00ff3a" }}>Idealny</span>;
    } else if (inside([feedData?.temp, feedData?.humidity], highComfort)) {
      setPerfect(false);
      return <span style={{ color: "#50d30e" }}>Bardzo Dobry</span>;
    } else if (inside([feedData?.temp, feedData?.humidity], mediumComfort)) {
      setPerfect(false);
      return <span style={{ color: "#bcf2a0" }}>Umiarkowany</span>;
    }
    setPerfect(false);
    return <span style={{ color: "red" }}>Niski</span>;
  };
  
  const mediumComfort = [
    [16, 75],
    [17, 85],
    [21, 82],
    [25, 60],
    [27, 35],
    [25, 15],
    [20, 20],
    [17, 35],
    [16, 75],
  ];
  const highComfort = [
    [17, 75],
    [23, 65],
    [25, 35],
    [19, 38],
    [17, 75],
  ];
  const sweetSpot = [
    [21, 55],
    [23.1, 55],
    [23.1, 45],
    [21, 45],
  ];

  return (
    <Card>
      {isLoading || isFetching ? 
        <div className="status__loader">
          <CircularProgress 
            size={100}
            color="primary" /> 
        </div> :
        <div className="status">
          <div className="status__title">Status</div>
          <div className="status__item">
            <div>Temperatura:</div>
            <div>{feedData?.temp}&deg;C</div>
          </div>
          <div className="status__item"><div>Status</div><Alert
            type="temp"
            value={feedData?.temp} /></div>
          <div className="status__item"><span>Wilgotność:</span><span>{feedData?.humidity}</span></div>
          <div className="status__item"><div>Status</div><Alert
            type="humidity"
            value={feedData?.humidity} /></div>
          <div className="status__item"><div>Komfort</div><ComfortAlert /></div>
          {isPerfect && <div className="status__item">Gratulacje! zostałeś kotłowym roku!</div> }
         
        </div>}
    </Card>
  );
};

export default Status;
