import React from "react";
import "./Section.scss";
const Section = ({children, reference}) => {
  return (
    <div
      ref={reference}
      className='section'>{children}</div>
  );
};

export default Section;
