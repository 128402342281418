import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const BASE_URL = "https://api.thingspeak.com/channels/74481/";

export const thingSpeakApi = createApi({
  reducerPath: "thingSpeakApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    getChannelData: builder.query({
      query: ({channelID, numberOfResults}) => `fields/${channelID}.json?results=${numberOfResults}`
    }),
    getFeeds: builder.query({
      query: () => "feeds.json?results=1"
    }),
   
  })
});

export const {
  useGetChannelDataQuery,
  useGetFeedsQuery,
} = thingSpeakApi;
