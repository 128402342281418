import React from "react";
import { useGetChannelDataQuery } from "../../api/thingSpeakApi";
import format from "date-fns/format";
import "./Graph.scss";
import { VictoryAxis, VictoryChart, VictoryLine, VictoryTheme, VictoryVoronoiContainer } from "victory";
import Card from "../Card/Card";
import Button from "@mui/material/Button";
import { ButtonGroup, CircularProgress } from "@mui/material";
const Graph = ({channelID, title, field}) => {
  const [numberOfResults, setNumberOfResults] = React.useState(72);
  const {data, isLoading, isFetching} = useGetChannelDataQuery({channelID: channelID, numberOfResults: numberOfResults}, {
    pollingInterval: 300000,
  });
  const mappedData = (data) => data?.feeds.map((item) => {
    return {
      x: format(new Date(item.created_at), "dd.MM HH:mm"),
      y: Number(item[field])
    };
  });
  return (
    <Card>
      <div className='graph'>      
        <div className='graph__title'> {title}</div>
        {isLoading || isFetching ? <div className='graph__loader'>
          <CircularProgress
            size={100}
            color="primary" /> </div>:
          <> 
          
            <VictoryChart
              padding={{top: 0, bottom: 50, left: 50, right: 60}}
              width={600}
              height={360}
              theme={VictoryTheme.material}
              containerComponent={
                <VictoryVoronoiContainer
                  mouseFollowTooltips
                  voronoiDimension="x"
                  labels={d => `${title}: ${d?.datum.y}  \n ${d?.datum.x} `}
                />
              }
            >
             
              <VictoryAxis
                dependentAxis
                theme={VictoryTheme.material}
              />
              <VictoryAxis
                fixLabelOverlap
                theme={VictoryTheme.material}
                style={{
                  tickLabels: {angle: -10, padding: 10}
                }}
              />
              <VictoryLine
                style={{
                  data: { stroke: "#c43a31" },
                  parent: { border: "1px solid #ccc"}
                }}
                data={mappedData(data)} 
              />
          
            </VictoryChart>
       
            <ButtonGroup
              variant="contained"
              aria-label="outlined primary button group">
              <Button
                variant="contained"
                onClick={() => setNumberOfResults(numberOfResults + 24)}>Więcej</Button>
              <Button
                variant="outlined"
                onClick={() => setNumberOfResults(72)}>Ostatnia doba</Button>
            </ButtonGroup>
          </>}
      </div>
   
    </Card>
  );
};

export default Graph;
