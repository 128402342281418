
import "./App.css";
import Menu from "./components/Menu/Menu";
import Graphs from "./components/Graphs/Graphs";
import { useGetFeedsQuery } from "./api/thingSpeakApi";
import HomeCameras from "./components/HomeCameras/HomeCameras";
import SkiCameras from "./components/SkiCameras/SkiCameras";
import Info from "./components/Info/Info";
import React, {useRef} from "react";
import Section from "./components/Section/Section";
const App = () => {
  const {data, isLoading, isFetching} = useGetFeedsQuery({
    pollingInterval: 300000,
  });
  // const data = {feeds:[{field1: 23.9, field2: 50}]};
  const infoRef = useRef(null);
  const cameraRef = useRef(null);
  const graphRef = useRef(null);
  return (
    
    <div className="App">
   
      <Menu {...{infoRef, cameraRef, graphRef}} />
      <div className="margin"></div>
      <Info {...{data, isLoading, isFetching, infoRef}} />
      <Graphs {...{data, isLoading, isFetching, graphRef}} />
      <Section reference={cameraRef}>  
        <HomeCameras {...{cameraRef}}/>
        <SkiCameras />
      </Section>
    </div>
  );
};

export default App;
