import React from "react";
import Camera from "../Camera/Camera";

const SkiCameras = () => {
  return (
    <>
      <Camera title="Skrzyczne">
        <iframe 
          title="skrzyczne"
          src="https://imageserver.webcamera.pl/umiesc/cos-szczyrk" 
         
          height="340" 
          border="0" 
          frameborder="0" 
        />
      </Camera>
      <Camera title="Jaworzyna">
        <iframe 
          title="jaworzyna"
          src="https://imageserver.webcamera.pl/umiesc/cos-szczyrk-jaworzyna" 
          height="340" 
          border="0" 
          frameborder="0" 
        />
      </Camera>
      <Camera title="Małe Skrzyczne">
        <iframe 
          title="male skrzyczne"
          src="https://imageserver.webcamera.pl/umiesc/szczyrk-male-skrzyczne" 
          height="340" 
          border="0" 
          frameborder="0" 
        />
      </Camera>
      <Camera title="Zbójnicka Kopa">
        <iframe 
          title="zbojnicka-kopa"
          src="https://imageserver.webcamera.pl/umiesc/szczyrk-zbojnicka-kopa" 
          height="340" 
          border="0" 
          frameborder="0" 
        />
      </Camera>
      <Camera title="Hala Skrzyczeńska - Kuflonka">
        <iframe 
          title="kuflonka"
          src="https://imageserver.webcamera.pl/umiesc/szczyrk-hala-skrzyczenska-2" 
          height="340" 
          border="0" 
          frameborder="0" 
        />
      </Camera>
      <Camera title="Pośredni - Juliany">
        <iframe 
          title="pośredni"
          src="https://imageserver.webcamera.pl/umiesc/szczyrk-wierch-posredni" 
          height="340" 
          border="0" 
          frameborder="0" 
        />
      </Camera>
      <Camera title="Gondola">
        <iframe 
          title="gondola"
          src="https://imageserver.webcamera.pl/umiesc/szczyrk-gondola" 
          height="340" 
          border="0" 
          frameborder="0" 
        />
      </Camera>
      <Camera title="Solisko">
        <iframe 
          title="solisko"
          src="https://imageserver.webcamera.pl/umiesc/szczyrk-solisko" 
          height="340" 
          border="0" 
          frameborder="0" 
        />
      </Camera>
      <Camera title="Biały Kryż">
        <iframe 
          title="bialy-krzyz"
          src="https://imageserver.webcamera.pl/umiesc/bialy-krzyz" 
          height="340" 
          border="0" 
          frameborder="0" 
        />
      </Camera>
      <Camera title="Bekskid Sport Arena - Góra">
        <iframe 
          title="bsa-góra"
          src="https://imageserver.webcamera.pl/umiesc/beskidsport" 
          height="340" 
          border="0" 
          frameborder="0" 
        />
      </Camera>
      <Camera title="Bekskid Sport Arena - Dół">
        <iframe 
          title="bsa-góra"
          src="https://imageserver.webcamera.pl/umiesc/beskidsport-dolna-stacja" 
          height="340" 
          border="0" 
          frameborder="0" 
        />
      </Camera>
    </>
  );
};

export default SkiCameras;
