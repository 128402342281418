
import React from "react";
import Camera from "../Camera/Camera";

const HomeCameras = ({cameraRef}) => {
  return (
    <>
      <Camera title="Widok z Balkonu">
        <iframe
          title="balkon"
          height="340"
          allowFullScreen
          src="https://rtsp.me/embed/T65Rd4nQ/"
          frameborder="0"
        />
      </Camera>
      <Camera title="Licznik Gazu">
        <iframe
          title="gaz"
          allowFullScreen
          height="340"
          src="https://rtsp.me/embed/ye5ARnQH/"
          frameborder="0"
        />
      </Camera>
      <Camera title="Mostek">
        <iframe
          title="gaz"
          allowFullScreen
          height="340"
          src="https://rtsp.me/embed/RSRbTNhZ/"
          frameborder="0"
        />
      </Camera>
    </>

  );
};

export default HomeCameras;
