import React from "react";
import Card from "../Card/Card";
import "./Camera.scss";
const Camera = ({children, title}) => {
  return (
    <Card>
      <div className="camera__title">
        {title}
      </div>
      <div className="camera__content">
        {children}
      </div>
    </Card>
  );
};

export default Camera;
