import React from "react";
import Section from "../Section/Section";
import Graph from "../Graph/Graph";
import GraphComfort from "../GraphComfort/GraphComfort";

const Graphs = ({data, isLoading, isFetching, graphRef}) => {
  return (
    <Section reference={graphRef}>
      <Graph
        channelID={1}
        title="Temperatura" 
        field="field1"
      />
      <Graph
        channelID={2}
        title="Wilgotność"
        field="field2" 
      />
      <Graph
        channelID={3}
        field="field3" 
        title="Punkt Rosy"
      />
      <GraphComfort
        {...{data, isLoading, isFetching}} />
    </Section>
  );
};

export default Graphs;
