const alerts = {
  temp: [
    { min: -999, max: -100, status: "brak danych", color: "white" },
    { min: -100, max: 4, status: "!!!AWARIA OGRZEWANIA!!!", color: "red" },
    { min: 4, max: 8, status: "Podtrzymanie", color: "blue" },
    { min: 8, max: 12, status: "Bardzo Zimno!!!", color: "aqua" },
    { min: 12, max: 16, status: "Jako Tako", color: "yellow" },
    { min: 16, max: 21, status: "Umiarkowanie", color: "#50d30e" },
    { min: 21, max: 23, status: "Idealnie", color: "#00ff3a" },
    { min: 23, max: 26, status: "Za Ciepło", color: "#50d30e" },
    { min: 26, max: 50, status: "Gorąco", color: "orange" },
    { min: 60, max: 999, status: "!!!POŻAR!!!", color: "red" },
  ],
  humidity: [
    { min: -999, max: -100, status: "brak danych", color: "white" },
    { min: 0, max: 10, status: "Sachara!!!", color: "orange" },
    { min: 10, max: 25, status: "Bardo Sucho", color: "yellow" },
    { min: 25, max: 35, status: "Sucho", color: "#ffff60" },
    { min: 35, max: 45, status: "OK", color: "#50d30e" },
    { min: 45, max: 55, status: "Idealnie", color: "#00ff3a" },
    { min: 55, max: 65, status: "OK", color: "#50d30e" },
    { min: 65, max: 80, status: "Wilgotno", color: "aqua" },
    { min: 80, max: 90, status: "Bardzo Wilgotno", color: "blue" },
    {
      min: 90,
      max: 999,
      status: "!!!Woda leje się po ścianach!!!",
      color: "red",
    },
  ],
};
export const getColor = (type, value) =>
  alerts[type].find((alert) => value > alert.min && value <= alert.max).color;

export const Alert = ({ type, value }) => {
  const alert = alerts[type].find(
    (alert) => value > alert.min && value <= alert.max
  );

  return (
    <span
      className="alert"
      style={{ color: alert?.color }}>
      {alert?.status}
    </span>
  );
};

export default Alert;
