import React, {useEffect, useState} from "react";
import "./Menu.scss";
import logo from "../../logo.png";
import logo_mobile from "../../logo_mobile.png";
import { Button } from "@mui/material";
const Menu = ({infoRef, graphRef, cameraRef}) => {
  
  const [width, setWidth] = useState();
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);
  return (
    <div className="menu"><img
      className="menu__logo"
      src={width > 1005 ? logo: logo_mobile}
      alt="logo"
        
    />
    <div className="menu__buttons">
      <Button
        onClick={() => infoRef?.current?.scrollIntoView({behavior: "smooth"})
        }> Status </Button>
      <Button
        onClick={() => graphRef?.current?.scrollIntoView({behavior: "smooth"})
        }> Wykresy </Button>
      <Button
        onClick={() => cameraRef?.current?.scrollIntoView({behavior: "smooth"})
        }> Kamery </Button>
    </div>
    </div>
  );
};

export default Menu;
