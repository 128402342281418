import React from "react";

import "../Graph/Graph.scss";
import { VictoryAxis, VictoryChart, VictoryScatter, VictoryTheme } from "victory";
import Card from "../Card/Card";

import { CircularProgress } from "@mui/material";
const GraphComfort = ({data, isLoading, isFetching}) => {

  return (
    <Card>
      <div className='graph'>      
        <div className='graph__title'> Komfort </div>
        {isLoading || isFetching ?
          <div className='graph__loader'>
            <CircularProgress
              size={100}
              color="primary" /> 
          </div>
          :
          <>    
            <VictoryChart
              padding={{top: 0, bottom: 80, left: 50, right: 60}}
              width={600}
              height={400}
              theme={VictoryTheme.material}
            >
              <VictoryAxis
                name="Temperatura"
                dependentAxis
                maxDomain={100}
                domain={[0, 100]}
                tickValues={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                theme={VictoryTheme.material}
              />
              <VictoryAxis
                domain={[0, 30]}
                tickValues={[2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30]}
                theme={VictoryTheme.material}
              />
              <svg
                width={600}
                height={400}>
                <defs>
                  <linearGradient
                    id="tempGradient"
                    gradientTransform="">
                    <stop
                      offset="15%"
                      stop-color="white" />
                    <stop
                      offset="60%"
                      stop-color="gold" />
                    <stop
                      offset="150%"
                      stop-color="red" />
                  </linearGradient>
                  <linearGradient
                    id="humGradient"
                    gradientTransform="rotate(90)">
                    <stop
                      offset="0"
                      stop-color="rgba(0, 120, 255,0.6)" />
                    <stop
                      offset="95%"
                      stop-color="rgba(0, 120, 255,0)" />
                  </linearGradient>
                  <linearGradient
                    id="comZone1Gradient"
                    gradientTransform="">
                    <stop
                      offset="0"
                      stop-color="rgba(12, 200, 120, 0.2)" />
                    <stop
                      offset="50%"
                      stop-color="rgba(12, 200, 120,1)" />
                    <stop
                      offset="100%"
                      stop-color="rgba(12, 200, 120,0.2)" />
                  </linearGradient>
                  <linearGradient
                    id="comZone2Gradient"
                    gradientTransform="">
                    <stop
                      offset="0"
                      stop-color="rgba(0, 220, 50, 0.1)" />
                    <stop
                      offset="50%"
                      stop-color="rgba(0, 220, 50,1)" />
                    <stop
                      offset="100%"
                      stop-color="rgba(0, 220, 50,0.1)" />
                  </linearGradient>
                  <linearGradient
                    id="comZone3Gradient"
                    gradientTransform="">
                    <stop
                      offset="0"
                      stop-color="rgba(0, 255, 20, 0.2)" />
                    <stop
                      offset="50%"
                      stop-color="rgba(0, 255, 20,1)" />
                    <stop
                      offset="100%"
                      stop-color="rgba(0, 255, 20,0.2)" />
                  </linearGradient>
                </defs>
                <rect
                  x="50"
                  y="0"
                  width="80"
                  height="320"
                  stroke="black"
                  fill="url('#humGradient')"
                  stroke-width="0"/>
          
                <rect
                  x="50"
                  y="0"
                  width="490"
                  height="320"
               
                  fill="url('#tempGradient')"
                  stroke-width="0"/>
                <rect
                  x="50"
                  y="0"
                  width="490"
                  height="320"
               
                  fill="url('#humGradient')"
                  stroke-width="0"/>

                <polygon
                  points="330
                   210 310 80 330 50 390 60 460 130 490 210 460 280 380 260"
               
                  fill="url('#comZone1Gradient')"
                
                />
                
                <polygon
                  points="330 80 420 100 455 200 360 200"
                  fill="url('#comZone2Gradient')"
                  stroke-width="1"
                />

                <polygon
                  points="390 145 430 145 430 175 390 175  "
                  
                  fill="url('#comZone3Gradient')"
                  stroke-width="1"
                />

              </svg>
              <VictoryScatter
                style={{ data: { fill: "#c43a31" } }}
                size={4}
                data={[
                  { x: Number(data?.feeds[0].field1), y: Number(data?.feeds[0].field2) },
                
                ]}
              />
            </VictoryChart>
          </>
        }
      </div>
   
    </Card>
  );
};

export default GraphComfort;
