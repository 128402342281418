import React from "react";
import Section from "../Section/Section";
import Status from "../Status/Status";
import Weather from "../Weather/Weather";

const Info = ({data, isLoading, isFetching, infoRef}) => {
  return (
    <Section
     
      reference={infoRef} >
      <Weather />
      <Status {...{data, isLoading, isFetching}}/>
  
    </Section>
  );
};

export default Info;
