import { configureStore } from "@reduxjs/toolkit";
import { thingSpeakApi } from "../api/thingSpeakApi";
export const store = configureStore({
  reducer: {
    [thingSpeakApi.reducerPath]: thingSpeakApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thingSpeakApi.middleware)
});
export default store;
